import React from 'react';
import './RightColumn.css';
// import ScrollSpyComponent from '../ScrollSpy/ScrollSpy';
// import PictureDisplay from '../Picture';


function RightColumn() {
    return (
        <div>
            {/* <PictureDisplay assetBucket={assetBucket}/> */}
            {/* <ScrollSpyComponent /> */}
        </div>
    );
}

export default RightColumn;
